import React from 'react';
import {graphql} from "gatsby";
import Markdown from "markdown-to-jsx";

import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import Layout from "../../components/layouts/Layout";
import FormSalesTemplate from "../../components/forms/FormSalesTemplate";

import Col from "../../components/styled/grid/Col";
import {Header2, Header3, Span} from "../../components/styled/typography/Typography";

const LandingTemplate = ({data}) => {

    const {sales_template} = data

    return (
        <Layout lang={sales_template.locale} seo={sales_template.SEO}>
            <SectionLayoutGrid customBackground="middle" wide="true" customPadding={"first"}>
                <Col span={8}>
                    <Header3><Span color="PAS-primary">{sales_template.title_color}</Span></Header3>
                    <Header2 color="white" marginBottom="normal">
                        {sales_template.title_white}
                    </Header2>
                    <div className="markdown">
                        <Markdown children={sales_template.description} options={{forceBlock: true}}/>
                    </div>
                </Col>
                <Col span={4}>
                    <FormSalesTemplate
                        locale={sales_template.locale}
                        list_token={sales_template.list_token}
                        form_title={sales_template.form_title}
                        form_image={sales_template.form_image.url}
                        slug={sales_template.slug}
                    />
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleSalesTemplates($locale: String, $slug: String) {
     sales_template: strapiTemplatesLandings(locale: {eq: $locale}, slug: {eq: $slug}) {
        locale
        slug
        title_color
        title_white
        description
        form_title
        form_image {
            url
        }
        list_token
        SEO {
             title
             isIndexable
             description
             keywords
             preview {
               url
             }
        }
     }
   }
`

export default LandingTemplate;